// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var footerContainer = "F_ch";
export var footerMenu = "F_cj";
export var menuItem = "F_br";
export var orderHorizontal = "F_ck";
export var orderVertical = "F_cl";
export var itemSeparator = "F_cm";
export var blankSeparator = "F_cn";
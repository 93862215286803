// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var container = "m_R";
export var headerContent = "m_S";
export var headerLogo = "m_T";
export var headerMenu = "m_V";
export var contentMenu = "m_W";
export var content = "m_h";